<template>
  <v-data-table
    :headers="headers"
    :items="items"
    dense
    disable-sort
    disable-pagination
    hide-default-footer
  >
    <template #top>
      <references-dialog :disabled="signed" />

      <ValidationProvider
        v-if="!signed && !isReceiptCorrect"
        ref="referencesTotalVP"
        :name="$t('amount.total')"
        :rules="sRules"
        class="d-none"
        immediate
        mode="eager"
        vid="_total"
      >
        <template #default="{ errors, valid, validate }">
          <v-text-field
            :error-messages="errors"
            :success="valid"
            :value="fPaidRounded"
            name="_total"
            type="text"
            @input="validate"
          >
          </v-text-field>
        </template>
        <input />
      </ValidationProvider>
    </template>

    <template #[`item.is_global`]="{ item }">
      <active-icon v-if="item.is_global" active />
    </template>

    <template #[`item.reason`]="{ item }">
      <div v-html="item.reason" />
    </template>

    <template #[`item.created_at`]="{ item }">
      <span v-if="item.invoice">
        {{ $dayjs(item.invoice.created_at).format("L LT") }}
      </span>
    </template>

    <template #[`item.balance_value`]="{ item }">
      <price-viewer
        v-if="item.invoice"
        :currency="item.invoice.currency"
        :label="true"
        :value="item.invoice.balance_value"
        chip
      />
    </template>

    <template #[`item.total_price_value`]="{ item }">
      <price-viewer
        v-if="item.invoice"
        :currency="item.invoice.currency"
        :label="true"
        :value="item.invoice.total_price_value"
        chip
      />
    </template>

    <template #[`item.balance_pending`]="{ item }">
      <price-viewer
        v-if="item.invoice"
        :currency="item.invoice.currency"
        :label="true"
        :value="item.invoice.balance_pending"
        chip
      />
    </template>

    <template #[`item.order_number`]="{ item }">
      <template v-if="item.invoice">
        <span class="font-weight-bold" v-text="item.invoice.order_serial" />
        <span class="ml-2" v-text="item.invoice.order_number" />
      </template>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { Component, Mixins, Ref } from "vue-property-decorator";
import type { DataTableHeader } from "@/mixins/DataTableMixin";
import DataTableMixin from "@/mixins/DataTableMixin";
import InvoiceMixin from "@/modules/invoices/mixins/InvoiceMixin";
import {
  InvoiceMovementType,
  InvoiceReference,
} from "@planetadeleste/vue-mc-gw";

import PriceViewer from "@/components/common/PriceViewer.vue";
import ReferencesDialog from "@/modules/invoices/components/references/ReferencesDialog.vue";
import ActiveIcon from "@/components/common/ActiveIcon.vue";
import type { ValidationProvider } from "vee-validate";
import { map, set } from "lodash";

@Component({
  components: { PriceViewer, ReferencesDialog, ActiveIcon },
})
export default class ReferencesTable extends Mixins(
  InvoiceMixin,
  DataTableMixin
) {
  @Ref("referencesTotalVP") readonly refTotalInput!: InstanceType<
    typeof ValidationProvider
  >;

  get items() {
    return map(this.references, (obReference) =>
      obReference instanceof InvoiceReference
        ? obReference.attributes
        : obReference
    );
  }

  get sRules() {
    const obRules: Record<string, any> = {};

    if (!this.fTotalReferences || this.signed) {
      return obRules;
    }

    // const fRound =
    //   this.currencyRate && this.iMovementType === InvoiceMovementType.CODE_SALES
    //     ? this.currencyRate.round
    //     : 1;
    // const fValue = roundBy(this.fTotalReferences, fRound);
    set(obRules, "max_value", this.fTotalReferences);

    return obRules;
  }

  /**
   * Invoice movement type is DEBIT NOTE
   * @returns {boolean}
   */
  get bIsDN(): boolean {
    return this.iMovementType === InvoiceMovementType.CODE_DEBIT_NOTE;
  }

  get useBalance(): boolean {
    return ![
      InvoiceMovementType.CODE_DEBIT_NOTE,
      InvoiceMovementType.CODE_RECEIPT_CORRECT,
      InvoiceMovementType.CODE_CANCEL_DEBT,
    ].includes(this.iMovementType);
  }

  mounted() {
    this.$nextTick(() => {
      const sTotalValue = this.useBalance
        ? "balance_value"
        : "total_price_value";
      const arHeaders: DataTableHeader[] = [
        { text: "global", value: "is_global", sortable: false },
        { text: "reference.reason", value: "reason", sortable: false },
        { text: "date", value: "invoice.created_at", sortable: false },
        {
          text: "invoice.type",
          value: "invoice.cfe_name",
          sortable: false,
        },
        {
          text: "invoice.dgi.number",
          value: "order_number",
          sortable: false,
        },
        { text: "total.invoice", value: sTotalValue, sortable: false },
        { text: "total.balance", value: "balance_pending", sortable: false },
      ];

      /*
       * Hide global and reason columns for receipt type or cancel/modify receipt (resguardo)
       */
      if (
        (this.isReceiptType && !this.isDebtRYA) ||
        this.iMovementType === 11
      ) {
        arHeaders.splice(0, 1);
        arHeaders.splice(0, 1);
      }

      this.setDTHeaders(arHeaders);
    });
  }
}
</script>
